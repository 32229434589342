import localDb from '@/store/db/local-db'
import vueCookies from 'vue-cookies'
import http from '@/api'
import { Toast } from 'vant'
import wx from 'weixin-js-sdk'

const init = async () => {
  const openId = getCacheOpenId()

  if (openId) {
    return
  }

  if (process.env.VUE_APP_CURRENTMODE === 'dev') {
    const openId = process.env.VUE_APP_OPENID
    localDb.set('_MH_WX_OPENID_', openId)
    vueCookies.set('_MH_WX_OPENID_', openId)
    return
  }

  const code = getUrlParam(window.location.search, 'code')

  if (code) {
    const openId = getCacheOpenId()
    if (openId) return openId

    const res = await http.post(`/web_api/wx/getWenXinOpenId?code=${code}`)
    if (res.success && res.data) {
      const openId = res.data
      localDb.set('_MH_WX_OPENID_', openId)
      vueCookies.set('_MH_WX_OPENID_', openId)
      return
    }
    Toast(`登录失败，请联系管理员`)
  } else {
    const AppID = process.env.VUE_APP_APPID
    const redirect_uri = window.location.href
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${AppID}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
  }
}
const getUrlParam = (url, name) => {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)') //构造一个含有目标参数的正则表达式对象
  var r = url.substring(1).match(reg) //匹配目标参数
  if (r != null) return unescape(r[2])
  return null //返回参数值
}
const getCacheOpenId = () => {
  return vueCookies.get('_MH_WX_OPENID_') || localDb.get('_MH_WX_OPENID_') || ''
}

/**************************************************** */
// 微信支付
export async function wxPay(data, callBack) {
  const config = await getSDKWxConfig()
  // 微信SDK授权配置
  wx.config(config)
  wx.ready(function () {
    wx.chooseWXPay({
      appId: data.appId,
      timestamp: data.timeStamp, // 支付签名时间戳
      nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
      package: data.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
      signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
      paySign: data.paySign, // 支付签名
      success: () => {
        // 支付成功后的回调函数
        callBack(true)
      },
      fail: () => {
        callBack(false)
      },
      cancel: function () {
        //TODO: 支付取消
        // Toast.fail({
        //   message: '支付取消',
        // })
      },
    })
  })
}

// 获取 JSSDK授权配置
async function getSDKWxConfig() {
  return new Promise((resolve) => {
    let config = vueCookies.get('MH_WXConfig')
    if (config) {
      resolve(config)
      return
    }

    const url = location.href.split('#')[0]
    http.post(`/web_api/wx/getWeChatJSSDK?url=${url}`).then((res) => {
      const wxConfig = res.data
      wxConfig.jsApiList = ['chooseWXPay']
      vueCookies.set('MH_WXConfig', wxConfig, '7200s')
      resolve(wxConfig)
    })
  })
}
/**************************************************** */

export { init, getCacheOpenId }
