const filters = {
  // filterTime为过滤器名字
  filterTime(_date, format) {
    // data 为管道符前面的数据   format 为传送的参数
    const data = new Date(_date)
    // 获取年份
    let year = data.getFullYear()
    // 获取月份加一然后转为字符串，用 padStart方法补零
    let months = (data.getMonth() + 1).toString().padStart(2, '0')
    let days = data.getDate()
    let hours = data.getHours().toString().padStart(2, '0')
    let minutes = data.getMinutes().toString().padStart(2, '0')
    let seconds = data.getSeconds().toString().padStart(2, '0')
    // 将传入的参数替换为获取的日期，链式调用，最后返回
    return format
      .replace('YYYY', year)
      .replace('MM', months)
      .replace('DD', days)
      .replace('hh', hours)
      .replace('mm', minutes)
      .replace('ss', seconds)
  },
  twoDecimal(value) {
    return value ? parseFloat(value).toFixed(2) : value
  },
}

export default (vm) => {
  Object.keys(filters).forEach((key) => {
    vm.filter(key, filters[key])
  })
}
