<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
#app {
  font-size: 16px !important;
}
</style>
