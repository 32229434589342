function get(key) {
  return localStorage.getItem(key)
}

function set(key, value) {
  localStorage.setItem(key, value)
}

function remove(key) {
  localStorage.removeItem(key)
}

function setJson(key, obj) {
  set(key, JSON.stringify(obj))
}

function getJson(key) {
  let jsonStr = get(key)
  if (jsonStr == null) {
    return null
  }
  return JSON.parse(jsonStr)
}
const localDb = {
  get,
  set,
  remove,
  getJson,
  setJson,
}
export default localDb
