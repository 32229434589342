import axios from 'axios'
import localDb from '@/store/db/local-db'
import { EventBus } from '@/utils/event-bus'
import { Toast } from 'vant'

axios.defaults.transformRequest = [
  function (data, headers) {
    if (headers['Content-Type'] == 'application/json') {
      return JSON.stringify(data)
    }
    if (data instanceof FormData) {
      return data
    }
    let ret = ''
    for (const it in data) {
      ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
    }
    return ret
  },
]
const http = axios.create({
  baseURL: process.env.VUE_APP_BASEURL,
  timeout: 30000,
})

const err = (error) => {
  Toast.clear()
  return Promise.reject(error)
}

//实例的请求拦截器
http.interceptors.request.use((config) => {
  Toast.loading({
    message: '加载中...',
    duration: 0, // 持续展示 toast
    forbidClick: true,
  })
  const token = localDb.get('token')
  if (token) {
    config.headers['token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config
}, err)

//实例的响应拦截器（并全局处理接口业务code,避免在每个接口中通过res.code=='0'去判断业务情况）
let SUCCESS = 0
//登录提示标识
let lock = false
http.interceptors.response.use((response) => {
  Toast.clear()
  if (response.headers['download-state'] != null) {
    return response
  }
  let res = response.data
  if (res == null || res.code == null) {
    return res
  }
  //默认success为false
  res.success = false
  //如果code==2 token失效 跳转到登录页面
  if (res.code == 2) {
    // console.log('用户中心页面提示token失效')
    if (lock) {
      return
    }
    lock = true
    EventBus.$emit('token-expire', '')
    setTimeout(() => {
      lock = false
    }, 1000)
    return
  }

  if (res.code == SUCCESS) {
    res.success = true
  }
  return res
}, err)

/**
 * 文件上传
 * @param url
 * @param formData
 * @returns {*}
 */
function upload(url, data) {
  return http({
    url: url,
    method: 'post',
    timeout: 0,
    data,
    headers: {
      'Content-Type': 'multiple/form-data',
    },
  })
}

function get(url, data = {}) {
  return http.get(url, {
    params: data,
  })
}

//只有一个参数时候，默认为form方式
//默认采用json数据格式提交，传入true时是form-data方式提交
function post(url, data = {}, isForm = false) {
  return http({
    url: url,
    method: 'post',
    data,
    headers: {
      'Content-Type': isForm ? 'application/x-www-form-urlencoded' : 'application/json',
    },
  })
}

function download(url, data, fileName) {
  return http({
    url: url,
    method: 'post',
    timeout: 0,
    data,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => {
    let url = window.URL.createObjectURL(new Blob([res.data]))
    let link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', fileName) // 自定义下载文件名（如exemple.txt）
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}

function downloadByRes(url, data, fileName) {
  return http({
    url: url,
    method: 'post',
    timeout: 0,
    data,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => {
    let url = window.URL.createObjectURL(new Blob([res]))
    let link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', fileName) // 自定义下载文件名（如exemple.txt）
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}

//导出选中/全部
export function exportPartData(url, data, type = false) {
  return http({
    url,
    method: 'post',
    responseType: 'blob',
    timeout: 0,
    headers: {
      'Content-Type': type ? 'application/x-www-form-urlencoded' : 'application/json',
    },
    data,
  })
}

export default {
  get,
  post,
  download,
  downloadByRes,
  upload,
}
