import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from '@/api'
import { init } from '@/utils/wxInfo'

import 'amfe-flexible'
import 'amfe-flexible/index.js'

import 'normalize.css'

import filters from '@/common/filters'
filters(Vue)

import VantPlugins from '@/plugins/vantPlugins'
Vue.use(VantPlugins)

Vue.prototype.$http = http

Vue.config.productionTip = false
;(async () => {
  await init()

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')
})()
