import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '@/views/Home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/userInfo',
    // name: 'home',
    // component: HomeView,
    // meta: {
    //   title: '首页',
    // },
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    component: () => import(/* webpackChunkName: "userInfo" */ '@/views/UserInfo.vue'),
    meta: {
      title: '用户信息',
    },
  },
  {
    path: '/addCard',
    name: 'addCard',
    component: () => import(/* webpackChunkName: "addCard" */ '@/views/AddCard.vue'),
    meta: {
      title: '用户信息添加',
    },
  },
  {
    path: '/bindingCard',
    name: 'bindingCard',
    component: () => import(/* webpackChunkName: "bindingCard" */ '@/views/BindingCard.vue'),
    meta: {
      title: '用户信息确认',
    },
  },
  {
    path: '/wannaPay',
    name: 'wannaPay',
    component: () => import(/* webpackChunkName: "WannaPay" */ '@/views/WannaPay.vue'),
    meta: {
      title: '生活缴费',
    },
  },
  {
    path: '/payments',
    name: 'Payments',
    component: () => import(/* webpackChunkName: "peyments" */ '@/views/Payments.vue'),
    meta: {
      title: '充值记录',
    },
  },
]

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '民和燃气'
  next()
})

export default router
