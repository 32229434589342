import {
  Button,
  Field,
  Popup,
  Popover,
  CheckboxGroup,
  Checkbox,
  Icon,
  List,
  Dialog,
  Toast,
  CellGroup,
  Cell,
  Tag,
  Empty,
} from 'vant'

export default {
  install(Vue) {
    const Comps = [
      Button,
      Field,
      Popup,
      Popover,
      CheckboxGroup,
      Checkbox,
      Icon,
      List,
      Dialog,
      Toast,
      CellGroup,
      Cell,
      Tag,
      Empty,
    ]
    Comps.forEach((Comp) => {
      Vue.use(Comp)
    })
  },
}
